.flip-box {
    background-color: transparent;
    /* width: 300px; */
    height: 120px;
    perspective: 1000px;
  }
  
  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
  
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 120px;
   
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-box-front {
    color: black;
  }
  
  .flip-box-back {
    color: white;
    transform: rotateY(180deg);
    
  }
  .custom-scrollbar::-webkit-scrollbar {
    width: 12px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #fff;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #BFE51C;
    border-radius: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  .carousel.carousel-slider .control-arrow {
    top: 10rem!important;
    background: none!important;
   
}