.react-tel-input .flag-dropdown {
    background-color: transparent!important;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
}
.react-tel-input .country-list .country.highlight {
    background-color: #15214c!important;
}
.react-tel-input .country-list .country:hover {
    background-color: #28345f!important;
    color: white!important;
}
.react-tel-input .country-list {
    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    background-color: #15214c!important;
    width: 300px;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
}